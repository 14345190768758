.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 45px;
  height: 45px;
  background: rgb(139, 200, 255);
  background: linear-gradient(
    90deg,
    rgba(139, 200, 255, 1) 0%,
    rgba(139, 200, 255, 1) 40%,
    rgba(255, 0, 0, 1) 50%,
    rgba(255, 123, 220, 1) 60%,
    rgba(255, 123, 220, 1) 100%
  );
  border: 12px solid;
  border-color: #510077 transparent #510077 transparent;
  border-radius: 50%;
  box-shadow: 2px 2px 10px red, -2px 2px 10px green, 2px -2px 10px blue,
    -2px -2px 10px black;
  transform: translate(-50%, -50%);
  animation: rotateSpinner 1.5s linear infinite;
  z-index: 2000;
}

@keyframes rotateSpinner {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
