@import "./variables.scss";
@import "./button.scss";

.newsContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  flex-shrink: 0;
}

.postsSection {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  max-width: 100%;
}

.publishBtn {
  @extend .sharedBtn;
  background-color: $greenBtn;
}

.editorContainer {
  width: 44%;
  max-height: 70vh;
  margin: 20px;
  box-shadow: $boxShadow;
  background-color: whitesmoke;
  overflow: hidden;
  transition: all 0.2s ease;
  cursor: pointer;
  iframe {
    pointer-events: none;
  }
  &:hover {
    transform: scale(1.02);
    box-shadow: $boxShadowFocus;
  }
  
  .sun-editor {
    width: 100%;
    border: 0;
    
    .se-container {
      .se-controller {
        display: none !important;
      }
      .se-line-breaker-component {
        display: none !important;
      }
    }
  }
}

.editorContainerTag {
  background: whitesmoke;
  width: fit-content;
  border: 1px solid red;
}

.singlePost {
  width: 95%;
  max-height: fit-content;
  .sun-editor {
    width: 90%;
  }
}



.headerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  h1,
  h4 {
    text-align: center;
    text-shadow: 0 0 2px $headerBackground;
  }
  h1 {
    margin: 2vh;
  }
  h4 {
    margin-bottom: 2vh;
  }
}

.notFound {
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 1vmax;
  transform: translate(-50%, -50%);
  box-shadow: $boxShadow;
  border-radius: 10px;
}

@media only screen and (max-width: 750px) {
  .postsSection {
    flex-direction: column;
  }
  .editorContainer {
    width: 94%;
  }
  .notFound {
    width: 95%;
  }
}

@media only screen and (max-width: 450px) {
  .headerContainer {
    h1 {
      font-size: 1.4rem;
    }
  }
  .editorContainer {
    width: 95%;
  }
}
