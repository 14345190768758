@import "./variables.scss";

.editContainer {
  width: 100%;
  min-width: 20%;
  min-height: fit-content;
  height: fit-content;
  padding: 1vmax;
  box-shadow: $boxShadow;
  background-color: whitesmoke;
  .postToolbarContainer {
    z-index: 20;
  }
}
