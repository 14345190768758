$transitionTime: 150ms;

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(128, 128, 128, 0.5);
  z-index: 11;
}

.backdropEnter {
  opacity: 0;
}

.backdropEnterActive {
  opacity: 1;
  transition: all $transitionTime;
}

.backdropExit {
  opacity: 1;
}

.backdropExitActive {
  opacity: 0;
  transition: all $transitionTime;
}
