@import "./variables";
@import "./basicLink";

.legislation {
  display: flex;
  flex-direction: column;
  h1,
  section {
    box-shadow: $sectionBoxShadow;
    border-radius: 5px;
    padding: 1rem;
  }
  h1 {
    text-align: center;
    text-shadow: 0 0 2px rgba(121, 134, 250, 0.8);
    width: 95%;
    align-self: center;
    margin: 2vh;
  }
  section {
    width: 95%;
    align-self: center;
    margin: 2vh 0;
  }
  h2 {
    margin: 1vmax;
    text-indent: 7%;
    font-weight: normal;
  }
  p {
    margin: 0 1vmax;
    font-size: 1.1rem;
  }
  a {
    @extend .basicLink;
  }
}
