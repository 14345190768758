@import "./variables.scss";
@import "./button.scss";

.publish {
  @extend .sharedBtn;
  background-color: $greenBtn;
  margin: 1vmax auto;
  &:disabled {
    cursor: not-allowed;
  }
}
