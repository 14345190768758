@import "./variables.scss";
@import "./button.scss";
@import "./card.scss";
@import "./label.scss";

.selectedContainer {
  display: flex;
  flex-direction: column;
}

.btnSection {
  display: flex;
  justify-content: space-around;
}

.editBtn {
  @extend .sharedBtn;
  background-color: $greenBtn;
}

.deleteBtn {
  @extend .sharedBtn;
  background-color: $redBtn;
}

.commentsSection {
  display: flex;
  flex-direction: column;
  margin: 2vmax;
  h2 {
    @extend .labelShared;
  }
  ul {
    list-style: none;
    margin: 0;
    li {
      @extend .cardShared;
      margin-bottom: 2vmax;
      &:hover {
        box-shadow: $boxShadow;
      }
    }
  }
}

.sendCommentBtn {
  @extend .sharedBtn;
  margin-left: 5vw;
}

.deleteModal {
  top: 30vh;
  h3 {
    padding: 3%;
    color: rgb(255, 172, 172);
    text-align: center;
    text-shadow: 0 0 2px $shadowColor, 0 0 2px $shadowColor,
      0 0 2px $shadowColor, 0 0 2px $shadowColor;
    letter-spacing: 0.1rem;
    border-bottom: 1px solid black;
  }
  main {
    display: flex;
    justify-content: space-around;
  }
}
