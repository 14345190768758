@import "./variables.scss";

.mainFooter {
  margin-top: auto;
  z-index: 3;
  height: $footerHeight;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: $headerBackground;
  box-shadow: 0 0 5px black;
  p {
    width: 100%;
    text-align: center;
  }
}
