@import "./variables.scss";

.linkShared {
  position: relative;
  text-decoration: none;
  width: fit-content;
  font-size: 1.1rem;
  padding: 0.25rem 0.6rem;
  color: black;
  border-radius: 5px;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: whitesmoke;
    transform: scaleX(0);
    transition: all 0.25s ease;
  }
  &:hover {
    box-shadow: 0 0 10px white;
  }
  &:hover::after {
    transform: scaleX(1);
  }
  &:visited {
    color: rgb(55, 85, 255);
  }
}

// .linkShared {
//   position: relative;
//   width: fit-content;
//   text-decoration: none;
//   color: black;
//   padding: 0.4vmax 1vmax;
//   border-radius: 5px;
//   text-shadow: 0 0 2px $shadowColor;
//   box-shadow: $boxShadow;
//   transition: all 0.25s ease-in-out;
// }
// .linkShared::after {
//   content: "";
//   border-bottom: 2px solid whitesmoke;
//   transform: scaleX(0);
//   transition: transform 0.5s ease-in-out;
// }
// .linkShared:hover {
//   box-shadow: 0 0 10px white;
// }
// .linkShared:focus {
//   color: rgb(3, 3, 34);
// }
// .linkShared:hover::after {
//   transform: scaleX(1);
// }
