@import "./variables.scss";
@import "./card.scss";
@import "./label.scss";
@import "./textInput.scss";
@import "./button.scss";
@import "./link.scss";

.loginContainer {
  width: 100%;
  // position: relative;
  // top: 0;
  // left: 0;
  min-height: 79vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 200ms linear;
}

.loginForm {
  @extend .cardShared;
  display: flex;
  flex-direction: column;
  width: 38%;
  margin-top: 2vmax;
  margin-bottom: 2vmax;
  background-color: $headerBackground;
  transition: all 0.2s ease;
  h2 {
    text-align: center;
    text-shadow: 0 0 2px $shadowColor;
  }
  label {
    @extend .labelShared;
  }
  input {
    @extend .sharedTextInput;
  }
  button {
    @extend .sharedBtn;
    background-color: $greenBtn;
    align-self: center;
  }
  p {
    margin: 1vmax;
    text-align: center;
    transition: all 250ms linear;
  }
  span {
    text-decoration: underline blue;
    user-select: none;
    cursor: pointer;
  }
}

@media only screen and (max-width: 750px) {
  .loginForm {
    width: 45%;
  }
}

@media only screen and (max-width: 650px) {
  .loginForm {
    width: 55%;
  }
}

@media only screen and (max-width: 450px) {
  .loginForm {
    width: 80%;
  }
}

@media only screen and (max-width: 350px) {
  .loginForm {
    width: 95%;
  }
}
