@import "./variables.scss";
@import "./button";

.singleComment {
  display: flex;
  justify-content: space-between;
  min-height: 10vmax;
  margin: 0 0 2vmax 0;
  background-color: whitesmoke;
}
.commentButtons {
  display: flex;
  flex-direction: column;
  border-left: 1px solid grey;
  opacity: 0.8;
  transition: opacity 0.2s ease-in;
  padding-left: 1vmax;
  &:hover {
    opacity: 1;
  }
  .editBtn {
    @extend .sharedBtn;
    background-color: $greenBtn;
    font-size: 14px;
  }
  
  .deleteBtn {
    @extend .sharedBtn;
    background-color: $redBtn;
    font-size: 14px;
  }
}

.editModal {
  display: flex;
  flex-direction: column;
  min-width: 60vw;
  h3 {
    text-align: center;
  }
}

.modalButtons {
  display: flex;
  justify-content: space-around;
}

.modalDeleteBtn {
  @extend .sharedBtn;
  background-color: $redBtn;
}

li .editBtn,
.deleteBtn {
  margin: 0.3vmax;
  font-size: 0.7rem;
  padding: 0.2rem;
}

.userInfo {
  display: flex;
  width: fit-content;
  border-bottom: 1px solid rgba(81, 69, 255, 0.3);
  padding: 0.2rem 0.5rem;
  margin-bottom: 0.6vmax;
  background-color: $mainBackground;
  border-radius: 8px;
  img {
    margin-right: 0.4vmax;
    width: 22px;
    height: 22px;
    border-radius: 50%;
  }
}

@media only screen and (max-width: 450px) {
  .editModal {
    min-width: 90vw;
  }
}
