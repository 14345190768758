@import "./variables.scss";
@import "./basicLink.scss";

.documente {
  display: flex;
  flex-direction: column;
  margin: 1vmax 0;
  width: 96%;
  align-self: center;
  h1,
  h2,
  h3 {
    text-shadow: 0 0 1px rgb(80, 80, 255);
    text-align: center;
  }
  h1 {
    margin-top: 1vmax;
  }
  section,
  h1 {
    padding: 1vmax;
    margin-bottom: 3vmax;
    border-radius: 5px;
    box-shadow: $sectionBoxShadow;
  }
  h2 {
    margin-bottom: 1vmax;
  }
  h3 {
    margin: 1vmax 0;
  }
  ul {
    list-style: circle;
    margin: 2vw 2vw 2vw 4vw;
  }
  li {
    padding: 0.2rem;
  }
  img {
    box-shadow: 0 0 1px;
  }
  img:hover {
    box-shadow: 0 0 3px;
  }
  p {
    text-indent: 2%;
    font-size: 1.1rem;
  }
}

.requestContainer {
  li {
    margin-bottom: 0.1rem;
  }
}

.cerereFig {
  width: 37vw;
  height: fit-content;
  text-decoration: none;
  color: black;
  float: right;
  padding-left: 2vw;
  img {
    width: 100%;
  }
  p {
    text-align: center;
  }
}

.exemplare {
  ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  a {
    @extend .basicLink;
  }
}

.acteStabilire {
  a {
    @extend .basicLink;
  }
}

.instiintari {
  p {
    margin-bottom: 1vmax;
  }
  aside {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  a {
    width: 95%;
    margin: 1vmax;
  }
  img {
    width: 100%;
  }
}

@media only screen and (max-width: 450px) {
  .requestContainer {
    display: flex;
    flex-direction: column-reverse;
  }
  .cerereFig {
    float: none;
    width: 98%;
    margin: 0;
    padding: 0;
    p {
      padding-top: 1vmax;
    }
  }
  .instiintari {
    aside {
      flex-direction: column;
    }
  }
}
