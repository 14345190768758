@import "./variables.scss";
@import './basicLink.scss';
@import './button.scss';
@import './card.scss';
@import './label.scss';
@import './link.scss';
@import './textInput.scss';
@import './App.module.scss';
@import './Welcome.module.scss';
@import './XBtn.module.scss';
@import "./SelectedPost.module.scss";
@import "./Header.module.scss";
@import "./Footer.module.scss";
@import "./Backdrop.module.scss";
@import "./Comment.module.scss";
@import "./CookiePolicy.scss";
@import "./Loading.module.scss";
@import "./Modal.module.scss";
@import "./PostedComment.module.scss";
@import "./PostEditor.module.scss";
@import "./Documents.module.scss";
@import "./Legislatie.module.scss";
@import "./Login.module.scss";
@import "./Materials.module.scss";
@import "./NewPost.module.scss";
@import "./Noutati.module.scss";

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  position: relative;
  min-height: 100%;
}

body {
  background-color: $mainBackground;
  font-family: "Times New Roman", Times, serif;
  width: 100%;
}

img {
  display: block;
  width: auto;
  height: auto;
}

.errorMessage {
  color: rgb(252, 49, 49);
  box-shadow: 0 0 3px white;
  background-color: rgb(253, 212, 212);
  opacity: 1;
  width: 100%;
  align-self: center;
  padding: 3px;
  border-radius: 5px;
}

.errorMessage-enter {
  opacity: 0;
  height: 0%;
}

.errorMessage-enter-active {
  opacity: 1;
  height: 100%;
  transition: all 250ms linear;
}

.errorMessage-exit {
  opacity: 1;
  height: 100%;
}

.errorMessage-exit-active {
  opacity: 0;
  height: 0%;
  transition: all 250ms linear;
}

.okMessage {
  opacity: 1;
  padding: 0 1vmax;
  background-color: rgb(190, 243, 190);
  border-radius: 4px;
}

.okMessage-enter {
  opacity: 0;
}

.okMessage-enter-active {
  opacity: 1;
  transition: opacity 250ms;
}

.okMessage-exit {
  opacity: 1;
}

.okMessage-exit-active {
  opacity: 0;
  transition: opacity 250ms;
}

.infoMessage-enter {
  opacity: 0;
}

.infoMessage {
  opacity: 1;
  font-size: "0.8rem";
  font-style: italic;
  font-size: 0.8rem;
}

.infoMessage-enter-active {
  opacity: 1;
  transition: opacity 250ms;
}

.infoMessage-exit {
  opacity: 1;
}

.infoMessage-exit-active {
  opacity: 0;
  transition: opacity 250ms;
}

.postToolbarContainer {
  z-index: 100;
}
