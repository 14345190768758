@import "./variables.scss";
@import "./link.scss";
@import "./button.scss";

.mainHeader {
  position: fixed;
  top: 0;
  width: 100%;
  height: 10vh;
  background-color: $headerBackground;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0 3px $shadowColor;
  z-index: 4;
}

.activeLink {
  box-shadow: 0 0 10px whitesmoke;
}

.navBar {
  width: 92%;
  transition: all 0.3s linear;
  z-index: 6;
  ul {
    height: 100%;
    margin: 0 2vmax;
    display: flex;
    list-style: none;
    justify-content: space-between;
    align-items: center;
  }
  li {
    padding: 0.2rem;
  }
  a {
    @extend .linkShared;
    display: inline-block;
    text-decoration: none;
    text-align: center;
  }
  a:visited {
    color: black;
  }
  button {
    @extend .sharedBtn;
    padding: 0.5vmax;
    font-size: 1rem;
    background-color: $redBtn;
  }
}

.logoLink {
  img {
    display: inline-block;
    width: 9vh;
    margin: 0 2vmax;
    transition: transform 1.2s ease-in-out;
  }
  img:hover {
    transform: rotate(1turn);
  }
}

.hamburger {
  position: fixed;
  display: none;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: space-between;
  top: 2vh;
  right: 4vw;
  width: 33px;
  height: 33px;
  padding: 3px;
  z-index: 7;
  cursor: pointer;
  .lineOne,
  .lineTwo,
  .lineThree {
    transition: all 0.3s linear;
    width: 25px;
    height: 4px;
    border-radius: 1px;
    background-color: black;
    transform-origin: center;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 0 2px whitesmoke;
  }
}

.activeHamburger {
  .lineOne {
    transform: rotateZ(45deg) translate(28%, 100%);
  }
  .lineTwo {
    transform: rotate(2turn);
    opacity: 0;
  }
  .lineThree {
    transform: rotateZ(-45deg) translate(28%, -100%);
  }
}

.activeNav {
  transform: translateX(0%);
}

@media only screen and (max-width: 750px) {
  .mainHeader {
    z-index: 6;
  }
  .hamburger {
    display: flex;
  }
  .logoLink {
    position: absolute;
    top: 0;
    left: 1vh;
    z-index: 7;
  }
  .navBar {
    height: 100vh;
    width: 100%;
    background-color: $headerBackground;
    transform: translateX(100%);
    ul {
      flex-direction: column;
      justify-content: center;
    }
    a {
      margin-bottom: 20%;
    }
  }
  .activeNav {
    transform: translateX(0%);
  }
}
