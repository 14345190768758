.basicLink {
  position: relative;
  text-decoration: none;
  padding: 0.1rem;
  width: fit-content;
  font-size: 1.1rem;
  color: rgb(29, 29, 255);
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: rgb(110, 132, 255);
    transform: scaleX(0);
    transition: all 0.2s ease;
  }
  &:hover {
    text-shadow: 0 0 1px;
  }
  &:hover::after {
    transform: scaleX(1);
  }
  &:visited {
    color: rgb(55, 85, 255);
  }
}
