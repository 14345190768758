@import "./variables.scss";

.sharedBtn {
  width: fit-content;
  font-size: 1rem;
  text-shadow: 0 0 1px $shadowColor;
  padding: 0.3rem 0.4rem;
  margin: 1vmax;
  border: 1px solid $headerBackground;
  border-radius: 1vmax;
  box-shadow: $boxShadow;
  transition: all 0.2s ease;
  font-family: inherit;
  cursor: pointer;
  &:hover {
    box-shadow: $boxShadowFocus;
  }
  &:focus {
    outline: none;
    box-shadow: $boxShadowFocus;
  }
  &:active {
    box-shadow: $boxShadow;
    transform: scale(0.98);
  }
}

@media only screen and (max-width: 450px) {
  .sharedBtn {
    font-size: 0.8rem;
  }
}
